import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../src/app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: any;

  constructor(private http: HttpClient) { }

  loadConfig(): Observable<any> {
    // Fallback to environment if config.json is not available
    return this.http.get('/assets/config.json').pipe(
      map((data: any) => {
        this.config = data;

        // Replace the redirect_uri placeholder with the actual window location origin
        if (this.config.auth0.authorizationParams.redirect_uri === '@@REDIRECT_URI@@') {
          this.config.auth0.authorizationParams.redirect_uri = window.location.origin;
        }

        return this.config;
      }),
      // In case of error (e.g., config.json not found), fallback to default environment
      catchError((error) => {
        console.error('Could not load external config, falling back to environment', error);
        this.config = { ...environment };
        return of(this.config);
      })
    );
  }

  get setting(): any {
    return this.config;
  }
}
