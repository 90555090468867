<mat-toolbar color="primary" class="navbar">
  <div class="head">
    <a routerLink="/" *ngIf="!isMobile">
      <img src="../../assets/images/logo-white.png" alt="Senstilelogo" class="logo" *ngIf="!isSensor" />
      <mat-icon class="icon" *ngIf="isSensor">home</mat-icon>
    </a>
  </div>

  <div class="tail"></div>

  <div class="menu-bar" style="display: flex; flex-direction: row; align-items: center; justify-content: center; gap: 1rem; height: 100%;">
    <a (click)="selectIcon('apps')" [ngClass]="{ 'selected': isSelected('apps') }" routerLink="/">
      <img style="width: 24px; height: 24px; object-fit: contain;" src="../../../assets/images/Group_selected.png" alt="apps">
    </a>
    <a (click)="selectIcon('weave')" [ngClass]="{ 'selected': isSelected('weave') }" routerLink="/">
      <img style="width: 24px; height: 24px; object-fit: contain;" src="../../../assets/images/Vector.png" alt="weave">
    </a>
    <a (click)="selectIcon('weave')" [ngClass]="{ 'selected': isSelected('weave') }" routerLink="/">
      <img style="width: 24px; height: 24px; object-fit: contain;" src="../../../assets/images/weave.png" alt="weave">
    </a>
    <a (click)="selectIcon('home')" [ngClass]="{ 'selected': isSelected('home') }" routerLink="/">
      <img style="width: 24px; height: 24px; object-fit: contain;" src="../../../assets/images/touch.png" alt="home">
    </a>
    <a (click)="selectIcon('folder')" [ngClass]="{ 'selected': isSelected('folder') }" routerLink="/">
      <img style="width: 24px; height: 24px; object-fit: contain;" src="../../../assets/images/file.png" alt="folder">
    </a>
    <a (click)="selectIcon('settings')" [ngClass]="{ 'selected': isSelected('settings') }" routerLink="/">
      <img style="width: 24px; height: 24px; object-fit: contain;" src="../../../assets/images/brain.png" alt="settings">
    </a>
  </div>

  <div class="tail">
    <ng-container *ngIf="auth.user$ | async as user">
      <div>
        <ng-container *ngIf="langs">
          <button mat-button [matMenuTriggerFor]="menu">
            <div class="lang">
              {{ activeLang?.toUpperCase() }}
              <mat-icon>expand_more</mat-icon>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item color="primary" *ngFor="let lang of langs">
              {{ lang.toUpperCase() }}
            </button>
          </mat-menu>
        </ng-container>

        <button *ngIf="!isMobile && !isSensor" mat-button [matMenuTriggerFor]="userMenu" style="border: none;">
          <div class="user">
            <img src="../../../assets/images/mat-avatar.png" alt="avatar" />
          </div>
        </button>

        <mat-menu style="border: none;" #userMenu="matMenu">
          <button mat-menu-item>{{ user.name }}</button>
          <button mat-menu-item (click)="openDialog()">About</button>
          <ng-container *ngIf="auth.isAuthenticated$ | async">
            <button mat-menu-item class="logout" (click)="logOut()">Logout</button>
          </ng-container>
        </mat-menu>
      </div>
    </ng-container>
  </div>
</mat-toolbar>
